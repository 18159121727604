import React from 'react';

import './Educators.scss';

const Educators = () => {
  return (
    <div className="page educators">
      <div className="block">
        <div className='educators-heading'>
          <div className='section-title'>
            For Educators
          </div>
          <div className="button">
            <a href="/contact">
              <div>Click here to learn more about our institutional services</div>
            </a>
          </div>
        </div>
        <div className='boxes'>
          <div>
            <div className='title'>Independent Educational Consultants</div>
            <ul>
              <li>Specialized offerings for members of HECA, IECA</li>
              <li>Complimentary practice exams and score report consultations</li>
              <li>Complimentary trial tutoring session for new students</li>
            </ul>
          </div>

          <div>
            <div className='title'>School Counselors</div>
            <ul>
              <li>Specialized offerings for members of NACAC or its subsidiaries</li>
              <li>Parent presentations and informational resources</li>
              <li>Small Group Tutoring and Bootcamps</li>
            </ul>
          </div>

          <div>
            <div className='title'>School Admin and Parent Teacher Orgs</div>
            <ul>
              <li>Specialized offerings for members of NASSP, PTA, PTO or their subsidiaries</li>
              <li>Larger scale district-wide practice exam and score tracking available</li>
            </ul>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Educators;
