import React from 'react';
import Founder from './founder/Founder.js';
import Vision from './vision/Vision.js';
import CoreValues from './core_values/CoreValues.js';

const About = () => {
  return (
    <div className="page">
      <Founder />
      <Vision />
      <CoreValues />
    </div>
  );
};

export default About;
