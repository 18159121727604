import React from "react";
import diamond from '../../../../assets/images/home/diamond-services.svg'
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "./Services.scss";

const Services = () => {
  return (
    <div className="services block">
      <img src={diamond}></img>
      <div className="services-block">
        <div className="services-left">
          <div className="services-text">
            Specializing in personalized tutoring programs for all 
            courses and admissions tests in American and British 
            education systems.
          </div>
          <div className="text-center">
            ACT - AP - GMAT - GRE - HSPT  -
            IB - IELTS - ISEE - LSAT - MCAT -
            PSAT - SAT - SSAT - TOEFL -
            College Courses -
            General Academics -
            Study Skills - 
            Academic Mentorship
          </div>
        </div>
        <div className="services-right">
          <div className="button inverted">
            <a href="/contact">Click here to schedule your complimentary consultation today</a>
          </div>

          {/* <div className="personable">
            Personable, engaging, and accessible, Rath Tutoring tutors act as
            mentors to their students and often work with them along the full arc of
            performance testing and achieving their educational goals, from
            standardized test prep to study skills, time management, and academic
            courses.
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Services;
