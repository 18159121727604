import React from "react";
import place from "../../../assets/images/footer/place.svg";
import phone from "../../../assets/images/footer/phone.svg";
import heca from "../../../assets/images/footer/heca.png";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share';
import "./Footer.scss";

const Footer = ({ admin }) => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      {!admin && (
        <footer>
          <div className="location">
            <div className="footer-title">Location</div>
            <div>
              <div className="flex-align-center margin-bottom-10">
                <img src={place} alt="phone icon" />
                <div>Worldwide via zoom</div>
              </div>
              <div className="flex-align-center">
                <img src={phone} alt="phone icon" />
                <div>1-866-RATH101 (1-866-728-4101)</div>
              </div>
            </div>
          </div>
          
          <div className="hours">
            <div className="footer-title">Office hours</div>
            <div>
              <div>10am - 6pm ET/PT</div>
              <div>Evenings and Weekends by Appointment</div>
            </div>
          </div>

          <div className="join">
            <div className="footer-title">Join our team</div>
            <div className="desktop">
              <div>
                Rath Tutoring is continually looking for the most
                experienced and professional talent in higher education to add
                to our team.
              </div>
              <a
                className="submit-application"
                href="mailto: admin@rathtutoring.com?subject=Application"
              >
                Submit an application
              </a>
            </div>
            <span className="mobile">
              Rath Tutoring is continually looking for the most
              experienced and professional talent in higher education to add to
              our team.
              <a
                className="submit-application"
                href="mailto: admin@rathtutoring.com?subject=Application"
              >
                Submit an application
              </a>
            </span>
          </div>

          <div className="copyright">
            <img src={heca} alt="logo heca" />
            <div className="blog-link">Read the latest updates on our <a href="/news">BLOG</a></div>
            <div>Copyright © Rath Tutoring {currentYear}</div>
            <div>
              ACT, AP, GMAT, GRE, HSPT, IB, ISEE, LSAT, MCAT, PSAT, SAT, SSAT,
              and TOEFL are registered trademarks not owned by Rath Tutoring.{" "}
            </div>
            <div>
              The trademark holders were not involved in the production of, and
              do not endorse, this website.
            </div>
            <div className='social-media-icons'>
              <a href="https://www.facebook.com/rathedugroup/" target="_blank"><FacebookIcon /></a>
              <a href="https://www.linkedin.com/company/rathedugroup/" target="_blank"><LinkedinIcon /></a>
              <a href="https://twitter.com/RathEduGroup/" target="_blank"><TwitterIcon /></a>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
